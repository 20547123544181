import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home from "./components/Pages/Home";
import Login from "./components/Pages/Login";
import Signup from "./components/Pages/Signup";
import Logout from "./components/Pages/Logout";

import Admin from "./components/Admin/Admin";

import AllBoards from "./components/Boards/AllBoards";
import Board from "./components/Boards/Board";
import EmbedBoard from "./components/Boards/EmbedBoard";

import Terms from "./components/Pages/Terms";
import Privacy from "./components/Pages/Privacy";

import "./chibaku.css";

const App = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/login" element={<Login />} />
                <Route path="/signup" element={<Signup />} />
                <Route path="/logout" element={<Logout />} />

                <Route path="/boards" element={<AllBoards />} />
                <Route path="/boards/:id" element={<Board />} />

                <Route
                    path="/embed/boards/:id"
                    element={<EmbedBoard embed={true} />}
                />

                <Route path="/terms" element={<Terms />} />
                <Route path="/privacy" element={<Privacy />} />

                <Route path="/admin" element={<Admin />} />
            </Routes>
        </BrowserRouter>
    );
};

export default App;
