import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import ProfileSettings from "../Modals/ProfileSettings";
import AccountSecurity from "../Modals/AccountSecurity";

import loadingGif from "./loading.gif";

import styles from "./Header.module.css";

const Header = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [modalOpen, setModalOpen] = useState(null);
    const [isBannerOpen, setIsBannerOpen] = useState(true);

    const openModal = (modal) => {
        setModalOpen(modal);
        setIsOpen(false);
    };

    useEffect(() => {
        const timestamp = localStorage.getItem("bannerDismissedAt");
        if (!timestamp) return true;

        // show banner if it's been more than 7 days since the user dismissed it
        const val =
            new Date(timestamp) <
            new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
        setIsBannerOpen(val);
    }, []);

    return (
        <>
            <header className={styles.header}>
                {isOpen && (
                    <div
                        className={styles.overlay}
                        onClick={() => setIsOpen(false)}
                    ></div>
                )}
                {isBannerOpen ? (
                    <section className={styles.container}>
                        <p className={styles.bannerText}>
                            Chibaku is a indie project, please consider
                            supporting us.
                        </p>
                        <div className={styles.bannerButtons}>
                            <a
                                href="https://store.anthonysmendes.com/l/support"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <button className={styles.bannerButton}>
                                    Sponsor us
                                </button>
                            </a>
                            <button
                                className={styles.bannerButton}
                                onClick={() => {
                                    localStorage.setItem(
                                        "bannerDismissedAt",
                                        new Date().toISOString()
                                    );
                                    setIsBannerOpen(false);
                                }}
                            >
                                Dismiss
                            </button>
                        </div>
                    </section>
                ) : (
                    <div className={styles.container}>
                        <h1 className={styles.title}>
                            <Link to="/boards">Chibaku</Link>
                        </h1>
                        <div className={styles.account}>
                            <button
                                onClick={() => setIsOpen(true)}
                                className={styles.button + " " + styles.account}
                            >
                                <img
                                    src={
                                        props.user.profilePicture
                                            ? props.user.profilePicture
                                            : loadingGif
                                    }
                                    alt={props.user.name}
                                />
                            </button>
                            {isOpen && (
                                <div className={styles.dropdown}>
                                    <div className={styles.profile}>
                                        <div className={styles.avatar}>
                                            <img
                                                src={props.user.profilePicture}
                                                alt={props.user.name}
                                            />
                                        </div>
                                        <div className={styles.name}>
                                            <p>{props.user.name}</p>
                                            <span>{props.user.email}</span>
                                        </div>
                                    </div>
                                    <div className={styles.divider}></div>
                                    <button
                                        onClick={() => openModal("settings")}
                                    >
                                        Profile
                                    </button>
                                    <button
                                        onClick={() => openModal("password")}
                                    >
                                        Security
                                    </button>
                                    <div className={styles.divider}></div>
                                    <Link to="/logout">
                                        <button>Logout</button>
                                    </Link>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </header>
            {modalOpen === "settings" && (
                <ProfileSettings
                    user={props.user}
                    setUser={props.setUser}
                    throwAlert={props.throwAlert}
                    onCloseHandler={() => setModalOpen(null)}
                />
            )}
            {modalOpen === "password" && (
                <AccountSecurity
                    throwAlert={props.throwAlert}
                    onCloseHandler={() => setModalOpen(null)}
                />
            )}
        </>
    );
};

export default Header;
