import styles from "./UserCard.module.css";

const UserCard = (props) => {
    return (
        <div className={styles.card}>
            <div>
                <h2>{props.user.name}</h2>
                <span>{props.user.email}</span>
            </div>
            <button onClick={() => props.viewUserHandler(props.user._id)}>
                View User
            </button>
        </div>
    );
};

export default UserCard;
