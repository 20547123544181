import { useState, useEffect } from "react";
import axios from "axios";

import Header from "./Header";
import RecentBoards from "./RecentBoards";
import BoardsList from "./BoardsList";

import NewBoardModal from "../Modals/NewBoard";

import Alert from "../Alert";
import FullLoading from "../FullLoading";

const AllBoards = () => {
    document.title = "My Boards - Chibaku";
    const [boards, setBoards] = useState([]);
    const [recentBoards, setRecentBoards] = useState([]);
    const [user, setUser] = useState({ name: "", email: "" });
    const [modalOpen, setModalOpen] = useState(null);
    const [alert, setAlert] = useState([false, ""]);
    const [pageLoading, setPageLoading] = useState(false);

    useEffect(() => {
        const token = localStorage.getItem("token");
        if (!token) {
            return (window.location.href = "/");
        }
    });

    useEffect(async () => {
        const { data } = await axios.post(
            "/boards/all-boards",
            {},
            {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            }
        );
        if (data.status === "ok") {
            setBoards(data.boards);
            setUser(data.user);
            setPageLoading(false);
        } else {
            throwAlert(data.message);
            if (data.message === "User not found") {
                localStorage.removeItem("token");
                return (window.location.href = "/");
            }
        }
    }, []);

    useEffect(async () => {
        const { data } = await axios.post(
            "/boards/recent-boards",
            {},
            {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            }
        );
        if (data.status === "ok") {
            setRecentBoards(data.boards);
        } else {
            throwAlert(data.message);
        }
    }, []);

    const throwAlert = (message) => {
        setAlert([true, message]);
        setTimeout(() => {
            setAlert([false, ""]);
        }, 2800);
    };

    const newBoardModalOpenHandler = () => {
        setModalOpen("new-board");
    };

    return (
        <>
            <Header
                user={user}
                setUser={(user) => setUser(user)}
                throwAlert={throwAlert}
            />
            <RecentBoards boards={recentBoards} />
            <BoardsList
                boards={boards}
                newBoardHandler={newBoardModalOpenHandler}
                throwAlert={throwAlert}
            />
            {modalOpen === "new-board" && (
                <NewBoardModal
                    setModalOpen={(state) => setModalOpen(state)}
                    throwAlert={throwAlert}
                />
            )}
            {alert[0] && <Alert message={alert[1]} />}
            {pageLoading && <FullLoading />}
        </>
    );
};

export default AllBoards;
