import styles from "./ViewUser.module.css";

const ViewUser = (props) => {
    const { user } = props;

    return (
        <div className={styles.overlay} onClick={props.closeModalHandler}>
            <div className={styles.main} onClick={(e) => e.stopPropagation()}>
                <div className={styles.header}>
                    <h1>Managing User</h1>
                    <button
                        className={styles.close}
                        onClick={props.closeModalHandler}
                    >
                        Close
                    </button>
                </div>

                <div className={styles.details}>
                    <p>
                        Name: <span>{user.name}</span>
                    </p>
                    <p>
                        Email: <span>{user.email}</span>
                    </p>
                    <p>
                        UID: <span>{user._id}</span>
                    </p>
                    <p>
                        Activities logged today:{" "}
                        <span>{user.todayJournalCount}</span>
                    </p>
                    <p>
                        Registered on:{" "}
                        <span>{`${new Date(user.createdAt).toLocaleString(
                            "default",
                            { month: "long" }
                        )} ${new Date(user.createdAt).getDate()}, ${new Date(
                            user.createdAt
                        ).getFullYear()}`}</span>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default ViewUser;
