import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";

import Header from "./Header";

import SearchBar from "./Cards/SearchBar";
import Card from "./Cards/Card";
import NewCard from "./Cards/NewCard";

import BoardUnauthorized from "../Modals/BoardUnauthorized";
import EditBoardTitle from "../Modals/EditBoardTitle";
import DeleteConfirmation from "../Modals/DeleteConfirmation";
import EmbedCode from "../Modals/EmbedCode";
import Members from "../Modals/Members";

import Alert from "../Alert";

import styles from "./Board.module.css";

const Board = ({ embed }) => {
    const navigate = useNavigate();
    const { id } = useParams();

    const [board, setBoard] = useState({ title: "", cards: [] });
    const [displayCards, setDisplayCards] = useState([]);
    const [alert, setAlert] = useState([false, ""]);
    const [overallLoading, setOverallLoading] = useState(true);
    const [newCardLoading, setNewCardLoading] = useState(false);
    const [noCards, setNoCards] = useState(false);
    const [error, setError] = useState(null);
    const [user, setUser] = useState({ name: "", email: "" });
    const [modalOpen, setModalOpen] = useState(null);
    const [cardsCount, setCardsCount] = useState(0);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [selectedCards, setSelectedCards] = useState([]);
    const [cogDropdown, setCogDropdown] = useState(false);

    if (!embed) document.title = `${board.title} - Chibaku`;

    const throwAlert = (message) => {
        setAlert([true, message]);
        setTimeout(() => {
            setAlert([false, ""]);
        }, 2800);
    };

    useEffect(() => {
        setDisplayCards(board.cards);
        if (board.cards.length === 0) {
            setNoCards(true);
        } else {
            setNoCards(false);
        }
    }, [board]);

    useEffect(() => {
        setCardsCount(board.cards.length);
    }, [board]);

    useEffect(async () => {
        const { data } = await axios.post(
            "/boards/get-board-details",
            { id },
            {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            }
        );
        if (data.status === "ok") {
            if (data.board.length === 0) {
                setNoCards(true);
            } else {
                setBoard(data.board);
                setUser(data.user);
                setOverallLoading(false);
            }
        } else {
            setError("access-denied");
        }
    }, []);

    const newCardHandler = async (text) => {
        setNewCardLoading(true);

        const card = {
            id: Math.floor(Math.random() * (999999 - 100001)) + 100000,
            text,
            createdAt: new Date(),
        };

        const { data } = await axios.post(
            "/cards/new-card",
            { boardId: id, cardText: card.text, cardId: card.id },
            {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            }
        );

        setNewCardLoading(false);

        if (data.status === "ok") {
            setBoard({ ...board, cards: [...board.cards, card] });
        } else {
            throwAlert(data.message);
        }
    };

    const cardDeleteHandler = async (cardId) => {
        const { data } = await axios.post(
            "/cards/delete-card",
            { boardId: id, cardId },
            {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            }
        );

        if (data.status === "ok") {
            setBoard({
                ...board,
                cards: board.cards.filter((card) => card.id !== cardId),
            });
        } else {
            throwAlert(data.message);
        }
    };

    const cardEditHandler = async (cardId, text) => {
        const { data } = await axios.post(
            "/cards/edit-card",
            { boardId: id, cardId, cardText: text },
            {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            }
        );

        if (data.status === "ok") {
            setBoard({
                ...board,
                cards: board.cards.map((card) =>
                    card.id === cardId ? { ...card, text } : card
                ),
            });
        } else {
            throwAlert(data.message);
        }
    };

    const searchCardsHandler = (query) => {
        if (query) {
            const matchedCards = board.cards.filter((card) =>
                card.text.toLowerCase().includes(query.toLowerCase())
            );
            if (matchedCards.length === 0) {
                setDisplayCards([]);
                setNoCards(true);
            } else {
                setDisplayCards(matchedCards);
                setNoCards(false);
            }
            return;
        }
        if (board.cards.length === 0) {
            setNoCards(true);
        } else {
            setNoCards(false);
        }
        setDisplayCards(board.cards);
    };

    const deleteBoardHandler = async () => {
        setDeleteLoading(true);

        const { data } = await axios.post(
            "/boards/delete-board",
            { id },
            {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            }
        );

        setDeleteLoading(false);

        if (data.status === "ok") {
            throwAlert("Board deleted successfully");
            navigate("/boards");
        } else {
            throwAlert(data.message);
        }
    };

    const scrollToTopHandler = () => {
        window.scroll({
            top: 0,
            behavior: "smooth",
        });
    };

    const selectCardHandler = (cardId) => {
        if (selectedCards.includes(cardId)) {
            setSelectedCards(selectedCards.filter((id) => id !== cardId));
        } else {
            setSelectedCards([...selectedCards, cardId]);
        }
    };

    const groupCopyHandler = () => {
        const selectedCardsText = selectedCards.map(
            (id) => board.cards.find((card) => card.id === id).text
        );
        navigator.clipboard.writeText(selectedCardsText.join("\n"));
        throwAlert("Copied to clipboard");
        setSelectedCards([]);
    };

    return (
        <>
            {embed ?? (
                <Header
                    user={user}
                    setUser={(user) => setUser(user)}
                    throwAlert={throwAlert}
                />
            )}
            <main
                className={styles.main}
                style={{
                    marginTop: embed ? "0" : "5rem",
                    maxWidth: embed ? "600px" : "100%",
                    padding: embed ? "1rem" : "0",
                }}
            >
                <div className={styles.header}>
                    {overallLoading ? (
                        <div
                            style={{ height: "40px", maxWidth: "320px" }}
                            className={styles.loadingDiv}
                        ></div>
                    ) : (
                        <h2>{board.title}</h2>
                    )}
                    <div className={styles.controls}>
                        <button
                            className={styles.cogBtn}
                            onClick={() => setCogDropdown((x) => !x)}
                        >
                            <i className="far fa-cog"></i>
                        </button>
                        {cogDropdown && (
                            <>
                                <div
                                    className={styles.overlay}
                                    style={{
                                        position: "fixed",
                                        top: "0",
                                        left: "0",
                                        right: "0",
                                        width: "100%",
                                        height: "100vh",
                                        zIndex: "100",
                                    }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setCogDropdown(false);
                                    }}
                                />
                                <div
                                    className={styles.dropdown}
                                    style={{
                                        zIndex: "101",
                                    }}
                                >
                                    <button
                                        onClick={() => {
                                            setModalOpen("edit");
                                            setCogDropdown(false);
                                        }}
                                    >
                                        {/* <i className="far fa-pen"></i> */}
                                        <span>Rename board</span>
                                    </button>
                                    <button
                                        onClick={() => {
                                            setModalOpen("embed-board");
                                            setCogDropdown(false);
                                        }}
                                    >
                                        {/* <i className="far fa-code"></i> */}
                                        <span>Embed board</span>
                                    </button>
                                    <button
                                        onClick={() => {
                                            setModalOpen("delete");
                                            setCogDropdown(false);
                                        }}
                                    >
                                        {/* <i className="far fa-trash-alt"></i> */}
                                        <span>Delete board</span>
                                    </button>
                                    <button
                                        onClick={() => {
                                            setModalOpen("members");
                                            setCogDropdown(false);
                                        }}
                                    >
                                        {/* <i className="far fa-user"></i> */}
                                        <span>Manage members</span>
                                    </button>
                                </div>
                            </>
                        )}
                    </div>
                </div>
                <SearchBar searchCardsHandler={searchCardsHandler} />
                {noCards && !overallLoading ? (
                    <div className={styles.noCards}>
                        <p>No cards found. Create some.</p>
                    </div>
                ) : null}
                {overallLoading && (
                    <>
                        <div
                            style={{ height: "70px" }}
                            className={styles.loadingDiv}
                        ></div>
                        <div
                            style={{ height: "130px" }}
                            className={styles.loadingDiv}
                        ></div>
                        <div
                            style={{ height: "90px" }}
                            className={styles.loadingDiv}
                        ></div>
                        <div
                            style={{ height: "180px" }}
                            className={styles.loadingDiv}
                        ></div>
                    </>
                )}
                {!overallLoading && (
                    <div className={styles.cards}>
                        {displayCards.map((card) => {
                            return (
                                <Card
                                    embededItem={true}
                                    key={card.id}
                                    id={card.id}
                                    text={card.text}
                                    boardId={id}
                                    onCardDelete={cardDeleteHandler}
                                    onCardEdit={cardEditHandler}
                                    selectCardHandler={() =>
                                        selectCardHandler(card.id)
                                    }
                                    selected={selectedCards.includes(card.id)}
                                />
                            );
                        })}
                    </div>
                )}
                {!embed && (
                    <div className={styles.scroll} onClick={scrollToTopHandler}>
                        Scroll to top
                    </div>
                )}
                {!embed && selectedCards.length === 0 && (
                    <NewCard
                        cardsCount={cardsCount}
                        freeUsersCardCount={20}
                        disabled={overallLoading}
                        newCardHandler={newCardHandler}
                        loading={newCardLoading}
                        setLoading={(state) => setNewCardLoading(state)}
                        throwAlert={throwAlert}
                    />
                )}
                {!embed && selectedCards.length !== 0 && (
                    <div className={styles.selectedCardsModal}>
                        <p>{selectedCards.length} cards selected</p>
                        <div className={styles.buttons}>
                            <button
                                onClick={() => setSelectedCards([])}
                                className={"button " + styles.cancel}
                            >
                                Clear
                            </button>
                            <button
                                onClick={groupCopyHandler}
                                className={"button "}
                            >
                                Copy
                            </button>
                        </div>
                    </div>
                )}
            </main>
            {!embed && alert[0] && <Alert message={alert[1]} />}
            {!embed && error === "access-denied" && <BoardUnauthorized />}
            {!embed && modalOpen === "edit" && (
                <EditBoardTitle
                    boardId={id}
                    currentTitle={board.title}
                    setModalOpen={(state) => setModalOpen(state)}
                    throwAlert={throwAlert}
                    changeTitle={(title) => setBoard({ ...board, title })}
                />
            )}
            {!embed && modalOpen === "delete" && (
                <DeleteConfirmation
                    type="board"
                    loading={deleteLoading}
                    onCloseHandler={() => setModalOpen(null)}
                    onConfirmation={deleteBoardHandler}
                    throwAlert={throwAlert}
                />
            )}
            {!embed && modalOpen === "embed-board" && (
                <EmbedCode
                    type="board"
                    id={id}
                    enabled={board.visibility}
                    onChangeHandler={(visibility) =>
                        setBoard({ ...board, visibility })
                    }
                    onCloseHandler={() => setModalOpen(null)}
                    onConfirmation={deleteBoardHandler}
                    throwAlert={throwAlert}
                />
            )}
            {!embed && modalOpen === "members" && (
                <Members
                    isAdmin={user._id === board.owner}
                    userId={user._id}
                    boardId={id}
                    members={board.members}
                    owner={board.owner}
                    onCloseHandler={() => setModalOpen(null)}
                    throwAlert={throwAlert}
                />
            )}
        </>
    );
};

export default Board;
